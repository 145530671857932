import Axios from "axios";
import { useState, useEffect } from "react";
import "./Pokemon.css";

function Pokemon() {
  const [pokemonName, setPokemonName] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const fetchPokemon = (num) => {
    Axios.get(`https://pokeapi.co/api/v2/pokemon/${num}`).then((res) => {
      let pn = res.data.species.name;
      let pn1 = pn.charAt(0).toUpperCase() + pn.slice(1);
      setPokemonName(pn1);

      if (res.data.sprites.front_default != null) {
        setImgUrl(res.data.sprites.front_default);
      }
      if (res.data.sprites.other.home.front_default != null) {
        setImgUrl(res.data.sprites.other.home.front_default);
      }
      if (res.data.sprites.other.dream_world.front_default != null) {
        setImgUrl(res.data.sprites.other.dream_world.front_default);
      }
    });
  };

  // Use useEffect to fetch a random Pokémon on initial load
  useEffect(() => {
    const randomNum = randomNumberInRange(1, 1000);
    fetchPokemon(randomNum); // Fetch a random Pokémon
  }, []);

  const handleClick = () => {
    const randomNum = randomNumberInRange(1, 1000);
    fetchPokemon(randomNum);
  };

  return (
    <div className="game">
      <img className="pokemonImage" src={imgUrl} alt={pokemonName}></img>
      <h3 className="pokemonName">{pokemonName}</h3>
      <button className="button-Pokemon" onClick={handleClick}>
        Generate Pokemon
      </button>
    </div>
  );
}

export default Pokemon;
